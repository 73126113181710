<template>
  <vs-modal
    :modalInstance="modalCreateHandoverProtocol"
    @ok="createHandoverProtocolPdf"
  >
    <base-cars-handover-protocol-form
      v-model="fields"
      :feedback="feedback"
    />

    <a class="d-none" ref="exportAnchorRef" />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { createHandoverProtocolError, createHandoverProtocolSuccess } from '@/libs/toasts/feedback/module/base';
import { computed, ref } from 'vue';
import formatDate from '@/libs/date-formatter';
import BaseCarsHandoverProtocolForm from '../forms/BaseCarsHandoverProtocolForm.vue';

export default {
  name: 'BaseCarsHandoverProtocolModul',
  emits: ['ok'],
  props: {
    resource: Object,
  },
  setup(props, { emit }) {
    const { modalCreateHandoverProtocol } = useBaseModals();

    const exportAnchorRef = ref(null);

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      resetFields,
      setFieldDefaults,
    } = useValidatedForm({
      resourceId: 'required',
      date: 'required',
      cadreEmployeeId: 'required',
      carEquipment: 'required',
      documentPreparationPlace: 'required',
      isForPrivatePurpouses: 'required',
    });

    setFieldDefaults({
      date: new Date(),
      resourceId: props.resource.id,
      isForPrivatePurpouses: false,
      carEquipment: '<ul><li><p>koło zapasowe – 1 szt.</p></li><li><p>gaśnica – 1 szt.</p></li><li><p>apteczka – 1 szt</p></li><li><p>dowód rejestracyjny – 1 szt.</p></li></ul>',
    });

    fields.value.cadreEmployeeId = computed(() => props.resource?.cadre_employee_id || null);

    modalCreateHandoverProtocol.value.on({
      close() {
        resetFields();
      },
    });

    const createHandoverProtocolPdf = async () => {
      if (!(await areAllFieldsValid())) return;

      modalCreateHandoverProtocol.value.isLoading = true;

      const anchorRef = exportAnchorRef.value;
      if (!anchorRef) return;

      const sendData = {
        ...fields.value,
      };

      useBaseApi()
        .createHandoverProtocol({
          ...sendData,
        })
        .then(({ data }) => {
          const timestamp = formatDate.standardTimed(Date.now());
          anchorRef.href = `data:application/pdf;base64,${data.data.html}`;
          anchorRef.download = `Budokop - Protokół odbiorczy - ${timestamp}.pdf`;
          anchorRef.click();

          showToast(createHandoverProtocolSuccess);
          modalCreateHandoverProtocol.value.close();
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          showToast(createHandoverProtocolError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalCreateHandoverProtocol.value.isLoading = false;
        });
    };

    return {
      modalCreateHandoverProtocol,
      fields,
      feedback,
      createHandoverProtocolPdf,
      exportAnchorRef,
    };
  },
  components: {
    VsModal,
    BaseCarsHandoverProtocolForm,
  },
};
</script>
