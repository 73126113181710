export default {
  assignResource: {
    base_first_resource_id: 'Zasób',
    base_second_resource_id: 'Przypisane zasoby',
  },
  resource: {
    name: 'Nazwa',
    register_number: 'Nr rejestracyjny',
    base_category_id: 'Kategoria',
    base_producer_model_id: 'Model',
    production_date: 'Rok produkcji',
    base_technology_id: 'Technologie',
    base_status_id: 'Status',
    base_resource_daily_cost: 'Koszt dzienny',
    base_resource_activity: {
      base_group_id: 'Grupa',
      base_resource_collaboration: {
        crm_company_id: 'Wynajmujący',
        start_date: 'Data wynajmu - od',
        end_date: 'Data wynajmu - do',
      },
    },
    serial_number: 'Nr ewidencyjny',
    meter_status_buy: '{unit} - zakup',
    meter_status_card_index: '{unit} - kartoteka',
    fuel_card: 'Karta paliwowa',
    fuel_card_pin: 'PIN',
    cadre_employee_id: 'Użytkownik',
    gps_panel: 'Link do panelu GPS',
    gear_type: 'Skrzynia',
    fuel_type: 'Rodzaj paliwa',
    engine_power_km: 'Moc silnika KM',
    engine_power_kw: 'Moc silnika kW',
    engine_size_cc: 'Pojemność silnika cm3',
    engine: 'Silnik',
    drivetrain: 'Napęd',
    tires: 'Opony',
    car_segment: 'Klasa samochodu',
    reservation_number: 'Nr rezerwacji',
    lease_agreement_number: 'Nr umowy najmu',
    notice_period: 'Okres wypowiedzenia',
    company_branch_id: 'Oddział',
    max_monthly_mileage: 'Limit kilometórw / mc',
    max_total_mileage: 'Limit kilometrów całk.',
  },
  car: {
    serial_number: 'VIN',
  },
  insurance: {
    date_from: 'Ubezpieczenie - od',
    date_to: 'Ubezpieczenie - do',
    policy_number: 'Nr polisy',
    coverage: 'Zakres ubezpieczenia',
    phone_number: 'Nr tel. Assistance',
    crm_provider_id: 'Towarzystwo ubezpieczeniowe',
    base_resource_id: 'Ubezpieczany pojazd',
    file: 'Załącznik',
  },
  transport: {
    name: 'Nazwa',
    transport_date: 'Data transportu',
    delivery_date: 'Planowana data odbioru',
    from_investment_id: 'Inwestycja początkowa',
    to_investment_id: 'Inwestycja docelowa',
    release_id: 'Osoba wydająca',
    receiver_id: 'Osoba odbierająca',
    base_transport_type_id: 'Rodzaj transportu',
    crm_provider_id: 'Przewoźnik',
    core_branch_id: 'Region',
    resources: 'Lista maszyn i sprzętu',
    status: 'Status transportu',
    resource: {
      id: 'Transportowany zasób',
      status: 'Status transportu',
      description: 'Uwagi',
    },
  },
  service: {
    type: 'Rodzaj serwisu',
    base_resource_id: 'Zasób',
    base_resource_service_status_id: 'Status',
    date: 'Data serwisu',
    next_date: 'Data następnego serwisu',
    meter_status: 'Przebieg',
    next_meter_status: 'Następny przebieg',
    description: 'Uwagi',
    reporting_user_id: 'Raportujący',
    accepting_user_id: 'Akceptujący',
  },
  report: {
    default: {
      base_work_type_id: 'Typ pracy',
      base_resource_id: 'Zasób',
      operator_id: 'Operator',
      reporter_id: 'Raportujący',
      report_date: 'Data raportu',
      machine: 'Zasób',
      meter_status: 'Stan licznika',
      amount_fuel: 'Zatankowane paliwo',
      purpose: 'Cel wyjazdu',
      route_description: 'Opis trasy',
      comment: 'Uwagi',
    },
    car: {
      operator_id: 'Kierowca',
      base_work_type_id: 'Typ jazdy',
      meter_status: 'Stan licznika',
    },
  },
  protocol: {
    handover: {
      date: 'Data',
      cadreEmployeeId: 'Użytkownik',
      carEquipment: 'Wyposażenie',
      documentPreparationPlace: 'Miejsce sporządzenia dokumentu',
      isForPrivatePurpouses: '',
    },
    return: {
      date: 'Data',
      cadreEmployeeId: 'Użytkownik',
      carEquipment: 'Wyposażenie',
      documentPreparationPlace: 'Miejsce sporządzenia dokumentu',
      includedMileageRecords: '',
    },
  },
};
